

.install-prompt .dialog-container {
    margin-bottom: 4rem;
    position: relative;  /* Required for absolute positioning of triangle */
    max-width: 42rem;
}

.install-prompt.ios .prompt-dialog, .install-prompt.android.samsung .prompt-dialog {
    align-items: end;
}

.install-prompt.ios.chrome .prompt-dialog,
.install-prompt.android.chrome .prompt-dialog {
    align-items: start;
}

.install-prompt.chrome .dialog-container {
    margin-top: 4rem;
}

/* iOS Safari - Point Down */
.install-prompt.ios.safari .dialog-container::after {
    content: '';
    position: absolute;
    bottom: -2rem;
    left: 50%;
    transform: translateX(-50%);
    border-left: 3rem solid transparent;
    border-right: 3rem solid transparent;
    border-top: 3rem solid #ffffff;  /* Points down */
    width: 0;
    height: 0;
    animation: pointBounceDown 2s cubic-bezier(0.68, -0.55, 0.265, 1.55) infinite;
}

/* iOS Safari - Point Down */
.install-prompt.android.samsung .dialog-container::after {
    content: '';
    position: absolute;
    bottom: -2.5rem;
    left: 87%;
    transform: translateX(-50%);
    border-left: 3rem solid transparent;
    border-right: 1rem solid transparent;
    border-top: 3rem solid #ffffff;  /* Points down */
    width: 0;
    height: 0;
    animation: pointBounceDown 2s cubic-bezier(0.68, -0.55, 0.265, 1.55) infinite;
}

/* iOS Chrome - Point Up */
.install-prompt.ios.chrome .dialog-container::after,
.install-prompt.android.chrome .dialog-container::after {
    content: '';
    position: absolute;
    top: -2rem;
    left: 87%;
    transform: translateX(-50%);
    border-left: 3rem solid transparent;
    border-right: 1rem solid transparent;
    border-bottom: 3rem solid #ffffff;  /* Points up */
    width: 0;
    height: 0;
    animation: pointBounceUp 2s cubic-bezier(0.68, -0.55, 0.265, 1.55) infinite;
}

@keyframes pointBounceDown {
    0%, 20%, 80%, 100% {
        transform: translateX(-50%) translateY(0);
    }
    40% {
        transform: translateX(-50%) translateY(-1rem);
    }
    60% {
        transform: translateX(-50%) translateY(0.3rem);
    }
}

@keyframes pointBounceUp {
    0%, 20%, 80%, 100% {
        transform: translateX(-50%) translateY(0);
    }
    40% {
        transform: translateX(-50%) translateY(1rem);
    }
    60% {
        transform: translateX(-50%) translateY(-0.3rem);
    }
}

.install-prompt .detail {
    margin-bottom: 4rem;
}

.install-prompt .dialog-description {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.install-prompt .instruction {
    /* Keep text alignment */
    text-align: left;
    font-weight: bold;
    margin-bottom: 1.8rem;
}

.install-prompt .icon-container {
    font-size: 5rem;
    
}