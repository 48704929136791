.message-card {

  &.demo-mode {
    top: 5.7rem;
  }

  & .header {
    margin-top: 1.6rem;
    text-align: left;
    position: relative;
    font-size: 1.4rem;

    & .person {
      width: 2.4rem;
      height: 2.4rem;
      margin-right: 0.4rem;
    }

    & .icon {
      width: 2.6rem;
      margin-right: 0.2rem;
      margin-bottom: 0.1rem;
    }

    & .message-sender {
      display: flex;
      align-items: center;
      width: auto;
      margin: 0.8rem 1.2rem;
    }

    & .message-details {
      width: auto;
      margin: 0.8rem 1.2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
  
      & .message-subject {
          flex: 1;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          line-height: 1.5;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          min-width: 0; /* Allow flex item to shrink below content size */
      }
  
      & .message-date {
          flex: 0 0 auto; /* Don't grow or shrink, use content width */
          white-space: nowrap; /* Prevent date from wrapping */
      }
  }

    & .note {
      position: absolute;
      bottom: -10.5rem;
      left: 0.4rem;
      margin-left: 0.8rem;
      font-size: 1.2rem;
    }
  }

  & .h-scroller {
    border: 0.4rem solid #eee;
    margin-bottom: 1.6rem;

    & .event {
      flex: 0 0 auto;
      width: 8rem;
      aspect-ratio: 1 / 1;
      margin: 0.1rem 0.4rem 0.1rem 0.1rem;
      padding: 0.6rem;
      text-align: left;


      -webkit-tap-highlight-color: transparent;
      /* Disable tap flash */
      user-select: none;
      /* Disable text selection */
      -webkit-user-select: none;
      /* For Safari */
      cursor: pointer;
      will-change: transform, opacity;

      position: relative;
      /* Ensures the pseudo-element is positioned relative to this container */

      box-shadow: 0 0.2rem 0.3rem rgba(0, 0, 0, 0.1);
      background-color: #f9f9f9;
      color: #999;
      border: 0.1rem solid #f9f9f9;
      transition: border 0.4s ease-in-out;
      /* Adjusted duration and easing */


      &:before {
        content: '';
        position: absolute;
        top: 0.9rem;
        /* Adjust as needed to position the circle exactly */
        left: 0.7rem;
        /* Adjust as needed */
        width: 0.5rem;
        /* Circle diameter */
        height: 0.5rem;
        /* Circle diameter */
        background-color: #bbb;
        /* Circle color */
        border-radius: 50%;
        /* Makes it a circle */
      }

      &.upcoming:before {
        background-color: #ff6700;
        /* Circle color */
      }

      &.firstview::before {
        display: none;
      }

      &.upcoming {
        background-color: #fff;
        color: #000;
        border: 0.1rem solid #ccc;
      }

      & .date {
        font-size: 0.9rem;
        font-weight: bold;
        padding-left: 0.9rem;
        transition: padding 0.2s ease-in-out,
      }

      &.firstview .date {
        padding-left: 0.1rem;
      }

      & .timing {
        position: absolute;
        font-size: 0.9rem;
        font-weight: bold;
        left: 0.7rem;
        bottom: 0.5rem;
        width: 6.4rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      & .title {
        margin-top: 0.2rem;
        font-size: 1.2rem;
        line-height: 1.1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        line-clamp: 3;
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
        text-wrap: wrap;
        hyphens: auto;
        /* Enable automatic hyphenation */
        hyphenate-limit-chars: 8 3 2;
        /* Min before, after, and total */
        overflow-wrap: break-word;
        /* Ensure long words can break if needed */
        word-wrap: break-word;
      }

      & .pdf-icon {
        color: #999;
        position: absolute;
        height: 1.2rem;
        top: 0.4rem;
        right: 0.3rem;
      }

      & .message {
        position: absolute;
        left: 0.7rem;
        bottom: 0.6rem;
        font-size: 0.8rem;
      }
    }
  }

  & .v-scroller {
    margin-top: 1rem;
    bottom: 0;
    padding: 0.6rem;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    border: 0.1rem solid #dcdcdc;
    border-radius: 1.2rem 1.2rem 2.6rem 2.6rem;
    background-color: #fff;
    display: flex;
    flex: 1;

    & .email-content {
      /* Basic resets */
      margin: 0;
      padding: 0;
      overflow-x: hidden;
      width: 100%;
      box-sizing: border-box;
      text-align: left;

      /* Table styles */
      & table {
        max-width: 100% !important;
        width: 100% !important;
        height: auto !important;
        border-collapse: collapse;
      }

      /* Image and div resets */
      & img,
      & div {
        max-width: 100% !important;
        height: auto !important;
      }

      /* Link handling */
      & a {
        target: _blank;
      }

      /* Additional email client specific resets */
      & [style*="position: absolute"] {
        position: relative !important;
      }

      & [style*="position: fixed"] {
        position: relative !important;
      }

    }

    & .dimmed {
      background-color: #fbfbfb;
    }

    & .url-details {
      height: 100%;
      width: 90%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 auto;
      flex: 1;
    }

    & .url-details h3 {
      padding-left: 2.5rem;
      color: #999;
    }

    & .url-details p {
      padding-left: 2.5rem;
      text-indent: -2.5rem;
      color: #999;
    }
  }

  .message-content .coachmark-background {
    position: absolute;
    top: 0.1rem;
    right: 3.6rem;
    z-index: 1000;
    padding: 1rem;
    padding-right: 2.5rem;
    padding-left: 6rem;
    background-color: #fbfbfb;
  }

  .message-content .coachmark {
    font-size: 1.4rem;
    color: #00BB00;
  }

  .message-content .coachmark .instruction {
    display: inline;
  }

  .message-content .close.coachmark {
    opacity: 0;
    /* Start invisible */
    animation:
      fadeIn 0.3s ease-in forwards 1.1s,
      /* Fade-in animation with delay */
      fadeStrobe 2.2s ease-in-out infinite 1.4s;
    /* Strobe animation starts after fadeIn (*/
  }

  .message-content .close.coachmark .icon {
    position: absolute;
    top: 1.1rem;
    right: -0.5rem;
  }

  .message-content .events.coachmark {
    position: absolute;
    top: 7.6rem;
    left: 11.5rem;
    opacity: 0;
    /* Start invisible */
    animation:
      fadeIn 0.3s ease-in forwards 0.7s;
    /* Fade-in animation with delay */
  }

  .message-content .events.coachmark .icon {
    position: absolute;
    top: -0.1rem;
    left: -2.4rem;
  }

  .message-content .coachmark.hide {
    opacity: 1;
    animation: fadeOut 0.2s ease-in forwards;
    /* Fade-out animation */
    animation-delay: 0s;
  }

  .message-content .coachmark .icon {
    display: inline;
    position: unset;
    color: #00D100;
    padding: 0.2rem;
  }
}