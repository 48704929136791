.watchlist {
    position: relative;
    min-height: 2rem;
    height: auto;
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
}

.watchlist .hint {
    width: 100%;
    color: #555;
    font-size: 1.2rem;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    margin-bottom: 0.2rem;
}

.watchlist .hint.show {
    opacity: 1;
}

.recommended-addresses {
    margin-top: 2.6rem;
    width: 90%;
    text-align: left;
    overflow: hidden;
}

.watchlist-addresses {
    box-sizing: border-box;
    top: 3.6rem;
    width: 100%;
    text-align: left;
    height: 0;
    max-height: 0;
    overflow: hidden;
    background-color: #fff;
    border: none;
    border-top: none;
    border-radius: 0 0 2.2rem 2.2rem;
    box-shadow: 0 0.2rem 0 0 #ccc;
}

.watchlist.expand .watchlist-addresses {
    border: 0.1rem solid #555555;
    border-top: none;
    height: auto;
    max-height: 12.6rem;
    animation: heightBounce 0.3s ease-out forwards;
}

.watchlist form {
    position: relative;
    z-index: 2;
    display: flex;
    width: 100%;
}

.watchlist input {
    transition: background-color 0.5s ease-in-out;
}

.watchlist input:focus {
    background-color: rgb(255, 255, 190);
}

.watchlist input.incomplete {
    color: red;
    background-color: rgb(255, 222, 222);
    animation: shake 0.4s ease-in-out;
}

.watchlist input:focus::placeholder {
    color: #aaa;
}

.watchlist .address-input {
    font-size: 1.5rem;
    border: 0.1rem solid #555555;
    border-radius: 2rem 0 0 2rem;
    padding: 0.8rem 0.8rem 0.8rem 1.6rem;
    flex: 1;
}

.watchlist .right-button {
    border-radius: 0 2rem 2rem 0;
    padding: 0.8rem 1.6rem 0.8rem 0.8rem;
    font-size: 1.5rem;
    border: 0.1rem solid #ff9e3d;
    background: #FF6700;
    color: #fff;
    box-shadow: none;
    flex-shrink: 0;
}

.watchlist button.clear {
    left: 71%;
    box-shadow: none;
}

.watchlist.expand .right-button {
    border-radius: 0 2rem 0 0;
}

.watchlist.expand .address-input {
    border-radius: 2rem 0 0 0;
}

.recommended-addresses .address-list {
    display: inline-flex; /* Keep inline-flex to make it flexible */
    flex-direction: row; /* Change to row for horizontal layout */
    overflow-x: auto; /* Enable horizontal scrolling */
    overflow-y: hidden; /* Hide vertical scroll if unnecessary */
    white-space: nowrap; /* Prevent items from wrapping to the next line */
    gap: 1rem; /* Optional: Add spacing between items */
    scroll-behavior: smooth; /* Optional: Smooth scrolling effect */
    scrollbar-width: none;
    padding: 1rem 0.4rem;
}

.recommended-addresses .address-list::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Edge */
}

.recommended-addresses .note {
    margin-left: 1rem;
    font-size: 1.2rem;
}

.recommended-addresses .note .icon {
    margin-right: 0.4rem;
}

.recommended-addresses .address {
    font-weight: normal;
    color:#555;
    border-radius: 2rem;
    background-color: #fff;
    box-shadow: 0 0.2rem 0 0 #ccc;
    width: auto;
    padding: 0.4rem 1.6rem 0.6rem 3rem;
}

.recommended-addresses .address-list .address .icon {
    top: 0.7rem;
    left: 1rem;
    color: #FF6700;
}

.address-list {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
    padding: 1rem 2rem;
}

.address {
    width: 100%;
    box-sizing: border-box;
    position: relative;

    margin-bottom: 1rem;
    padding: 0 2rem;
}

.address .entry {
    font-size: 1.6rem;
    width: inherit;
    box-sizing: border-box;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.address .icon {
    position: absolute;
    top: 0.2rem;
    left: 0;
}

.address-list .address .icon {
    color: #bbb;
}

.watchlist-addresses .address-list {
    padding-top: 1.6rem;
}

.watchlist-addresses .address.saved .icon {
    color: green;
}

.watchlist-addresses .address button.delete {
    box-shadow: none;

    position: absolute;
    top: 0;
    left: auto;
    right: -2.4rem;
    width: 6rem;

    display: flex;
    justify-content: center;
    align-items: center;
}

.watchlist-addresses .address button.delete .icon {
    color: #bbb;
    line-height: 1;
    width: inherit;
}

/* JS sets --current-height --dynamic-height */
@keyframes heightInBounce {
    0% {
        max-height: var(--current-height);
        /* Start at current height */
        opacity: 1;
    }

    50% {
        max-height: calc(var(--dynamic-height) * 1.1);
        /* Overshoot */
        opacity: 1;
    }

    70% {
        max-height: calc(var(--dynamic-height) * 0.98);
        /* Slight undershoot */
        opacity: 1;
    }

    100% {
        max-height: var(--dynamic-height);
        /* Final height */
        opacity: 1;
    }
}

@keyframes heightBounce {
    0% {
        max-height: var(--current-height);
        /* Start at current height */
        opacity: 1;
    }

    30% {
        max-height: calc(var(--dynamic-height) * 1.1);
        /* Overshoot */
        opacity: 1;
    }

    60% {
        max-height: calc(var(--dynamic-height) * 0.9);
        /* Undershoot */
        opacity: 1;
    }

    100% {
        max-height: var(--dynamic-height);
        /* Final height */
        opacity: 1;
    }
}



@keyframes shake {

    0%,
    100% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(-4px);
    }

    50% {
        transform: translateX(4px);
    }

    75% {
        transform: translateX(-4px);
    }
}