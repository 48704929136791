.create-container {
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 210;
}

.create-container.expand {
    pointer-events: auto;
}

.create-container .create-controls {
    position: fixed;
    bottom: 1.7rem;
    right: 2rem;
    width: 5rem;
    height: 5rem;
    animation: scaleInBounce 0.5s cubic-bezier(0.42, 0, 0.58, 1) forwards;
    animation-delay: 3s;
    opacity: 0;
}

@media (display-mode: fullscreen) {
    .create-container .create-controls {
        bottom: calc(2rem + env(safe-area-inset-bottom));
    }
}

.create-container button.create {
    pointer-events: auto;
    width: inherit;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ff6700;
    border-radius: 50%;
    box-shadow: 0 0.3rem 0 0 #ccc;
    color: white;
    font-size: 2rem;
}

.create-container button.create:active,
.create-container button.create:focus {
    transform: translateY(0.2rem);
    /* Applies only on press or focus */
}

.create-container.expand button.create {
    background-color: transparent;
    box-shadow: none;
}

.create-container .overlay {
    opacity: 0;
    pointer-events: none;
    /* Prevents blocking clicks */
}

.create-container.expand .overlay {
    position: absolute;
    /* Positioned relative to .create-container */
    bottom: 0;
    right: 0;
    width: 100%;
    /* Matches the parent width */
    height: 100%;
    /* Matches the parent height */
    background-color: rgba(255, 255, 255, 1);
    clip-path: circle(0% at 100% 100%);
    /* Circle starts at bottom-right */
    animation: expandCircle 0.5s ease-in-out forwards;
    /* Animates the circle */
    opacity: 1;
    pointer-events: auto;
}

@keyframes expandCircle {
    0% {
        clip-path: circle(0% at 100% 100%);
    }

    100% {
        clip-path: circle(150% at 100% 100%);
    }
}

.create-container button.create .icon {
    width: 3.2rem;
    height: 3.2rem;
    color: #fff;
    filter: drop-shadow(0 0.1rem #999);
    transform: rotate(0deg);
    /* Initial rotation */
    transition: transform 0.3s ease, width 0.3s ease, height 0.3s ease, color 0.3s ease, filter 0.3s ease;
    /* Smooth animations */
}

.create-container button.create .icon.fetching {
    animation: rotateSpinnerAndPause 2.5s ease-in-out infinite;
}

.create-container.expand button.create .icon {
    width: 2rem;
    /* Animate to smaller size */
    height: 2rem;
    /* Animate to smaller size */
    color: #222;
    /* Change color */
    filter: none;
    /* Remove shadow */
    transform: rotate(45deg);
    /* Rotate 45 degrees */
    animation: none;
}

.create-container .progress-animation, .create-container .success-animation {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.create-container .progress-animation {
    width: 4.4rem;
    height: 4.4rem;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

.create-container .progress-animation.fetching {
    opacity: 1;
}

.create-container .progress-animation .icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.create-container .progress-animation .wand {
    z-index: 2;
    color: #444;

    animation: rotateSpinnerAndPause 2.5s ease-in-out infinite;
    transform-origin: center;
    /* Ensures smooth rotation around the center */
}

@keyframes rotateSpinnerAndPause {
    0% {
        transform: rotate(0deg);
    }

    20% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.create-container .progress-animation .sparkles {
    z-index: 1;
    color: #ff6700;
    opacity: 1;
    /* Start invisible */
    transform: scale(1.12);
    animation: sparklePulse 2.5s ease-in-out infinite;
}

@keyframes sparklePulse {
    0% {
        opacity: 0;
        transform: rotate(0deg);
    }

    20% {
        transform: rotate(0deg);
    }

    40% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 0;
        transform: rotate(360deg);
    }
}


.create-container .hint {
    width: 100%;
    color: #555;
    font-size: 1.2rem;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    margin-bottom: 0.2rem;
}

.create-container .hint.show {
    opacity: 1;
}

.create-container input.incomplete {
    color: red;
    background-color: rgb(255, 222, 222);
    animation: shake 0.4s ease-in-out;
}

.create-container .create-form, .create-container .results-continue {
    position: absolute;
    bottom: 8rem;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
}

.create-container .create-form {
    width: 80%;
}

.create-container .create-form form {
    display: flex;
    align-items: center;
}

.create-container .note {
    margin-top: 1rem;
    font-size: 1.3rem;
}

.create-container .create-form .url-input {
    box-sizing: border-box;
    font-size: 1.4rem;
    border: 0.1rem solid #555;
    border-right: none;
    border-radius: 2rem 0 0 2rem;
    box-shadow: 0 0.2rem 0 0 #ccc;
    padding-left: 1.6rem;
    padding-right: 3rem;
    width: 80%;
    height: 3.4rem;
    flex: 1;
}

.create-container .create-form.fetching .url-input {
    padding-right: 0.2rem;
}

.create-container .create-form button.clear {
    background-color: transparent;
    box-shadow: none;

    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.7rem;
    height: 3.8rem;
    left: auto;
    right: 4.4rem;
    width: 1.5rem;
    opacity: 1;
}

.create-container .create-form.fetching button.clear {
    opacity: 0;
}

.create-container .create-form button.clear .icon {
    width: 1.6rem;
    background-color: #fff;
}

.create-container .create-form.fetching button.right-button .icon {
    animation: rotateSpinner 2s linear infinite;
}

.create-container .create-form button.right-button .icon {
    position: relative;
    color: #fff;
    top: -0.1rem;
}

.create-container .create-form button.right-button {
    box-sizing: border-box;
    font-size: 1.4rem;
    border: 0.1rem solid #ff9e3d;
    border-radius: 0 2rem 2rem 0;
    background-color: #ff6700;
    padding-left: 1rem;
    height: 3.4rem;
    flex-shrink: 0;
}

.create-container button.continue {
    background-color: #ff6700;
    color: #fff;
    margin-bottom: 0rem;
}