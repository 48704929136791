.bolt-card {

    & .header {
        display: flex;
        align-items: center;
        justify-content: center;

        & h2 {
            position: relative;
            font-size: 1.7rem;
            margin: 2.6rem 0 0 0;
            padding: 0;
            text-align: center;
            width: fit-content;
        }

        & .circle {
            position: absolute;
            top: -0.3rem;
            right: -3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2.6rem;
            height: 2.6rem;
            aspect-ratio: 1 / 1;
            border-radius: 50%;
            background-color: #ff6700;
            box-sizing: border-box;
            text-shadow: none;
        }

        & .event-count {
            font-size: 1.4rem;
            font-weight: bold;
            color: #fff;
        }
    }


    & .v-scroller {
        margin-top: 0.4rem;
        width: 100%;
    }

    & .event {
        display: flex;
        aspect-ratio: unset;
        align-items: center;
        box-sizing: content-box;
        width: auto;
        padding: 0.8rem 1.2rem;
        box-shadow: 0 0.2rem 0 0 #ccc;
        border-radius: 1.2rem;
        background-color: #fff;
        color: #000;
        border: 0.1rem solid #ccc;
        height: 3.2rem;
        margin: 0.4rem;

        & .icon {
            color: #bbb;
            margin-right: 1rem;
            font-size: 1.6rem;
        }

        & .event-details {
            flex: 1;
            display: flex;
            align-items: center;
            font-size: 1.3rem;

            & .event-name {
                text-align: left;
                width: 60%;
                padding-right: 1rem;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            & .event-date {
                width: 40%;
            }
        }

        & .event-link {
            color: #007AFF;
            text-decoration: none;
        }

        & .source {
            width: 2.8rem;
            height: 2.8rem;
            border-radius: 50%;
            margin-right: -0.4rem;
            font-size: 1.2rem;
            color: #777;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            box-shadow: 0 0.2rem 0 0 #eee;
        }
    }

    & .event:before {
        content: '';
        position: absolute;
        top: 0.8rem;
        /* Adjust as needed to position the circle exactly */
        left: 0.8rem;
        /* Adjust as needed */
        width: 0.5rem;
        /* Circle diameter */
        height: 0.5rem;
        /* Circle diameter */
        background-color: #ff6700;
        /* Circle color */
        border-radius: 50%;
        /* Makes it a circle */
    }

    & .event.firstview:before {
        opacity: 0;
    }


    & .event-updated {
        text-align: right;
        color: #777;
        font-size: 1.2rem;
        margin-bottom: -0.2rem;
        margin-right: 1.2rem;
        margin-top: 1.4rem;
    }
}