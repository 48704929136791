.progress-counter {
    --initial-width: 1.2rem;
    --expanded-width: 15rem;

    z-index: 100;
    position: fixed;
    right: calc(50% - (var(--expanded-width) + 1.6rem) / 2);
    /* TODO: ADJUST for PADDING */
    bottom: 3rem;
    padding-top: 0.3rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    padding-bottom: 0.5rem;
    font-size: 1.4rem;
    color: #333;
    background-color: #fff;
    border: 0.1rem solid #aaa;
    box-shadow: 0 0.2rem 0 0 #ccc;

    width: var(--initial-width);
    height: 1.6rem;
    border-radius: 1.2rem;
    opacity: 0;
    overflow: hidden;
    text-align: center;
    animation:
        scaleInBounce 0.5s cubic-bezier(0.42, 0, 0.58, 1) forwards,
        expandToPill 1s cubic-bezier(0.42, 0, 0.58, 1) forwards;
    animation-delay: 2s, 2.2s;
}

@media (display-mode: fullscreen) {
    .progress-counter {
        bottom: calc(3rem + env(safe-area-inset-bottom));
    }
}

.progress-counter.complete {
    animation:
        scaleInBounce 0.5s cubic-bezier(0.42, 0, 0.58, 1) forwards,
        expandToPill 1s cubic-bezier(0.42, 0, 0.58, 1) forwards,
        collapseToCircle 1s cubic-bezier(0.42, 0, 0.58, 1) forwards,
        scaleOutBounce 0.5s cubic-bezier(0.42, 0, 0.58, 1) forwards;
    animation-delay: 2s, 2.2s, 6s, 7s
}

.progress-counter .eventCount {
    display: inline-block;
    opacity: 0;
    animation:
        textAppear 1s cubic-bezier(0.42, 0, 0.58, 1) forwards;
    animation-delay: 2.7s;
}

.progress-counter.complete .eventCount {
    display: inline-block;
    animation:
        textAppear 1s cubic-bezier(0.42, 0, 0.58, 1) forwards,
        textDisappear 1s cubic-bezier(0.42, 0, 0.58, 1) forwards;
    animation-delay: 2.7s, 5.8s;
}

.progress-counter .progress {
    position: absolute;
    left: 0.7rem;
    top: 0.5rem;
    opacity: 1;
}

.background-circle {
    fill: none;
    stroke: #dcdcdc;
}

.progress-circle {
    fill: none;
    stroke: #ff6700;
    transform: rotate(-90deg);
    transform-origin: center;
    transition: stroke-dashoffset 0.3s ease;
}

.progress.loading .indicator {
    animation: rotate 0.6s linear infinite, strobe 0.6s ease-in-out infinite;
}

.progress.active .indicator {
    animation: rotate 2.6s linear infinite, strobe 2.6s ease-in-out infinite;
}

.progress.complete .indicator {
    animation: none;
    opacity: 1;
    transition: opacity 5.2s ease-out;
}