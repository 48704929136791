.person-component {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    color: #777;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 0.2rem 0 0 #eee;

    & .initials {
        position: relative;
        font-size: 1.2rem;
        text-align: center;
    }
}

