/* Overlay to darken the background */
.prompt-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.prompt-dialog .overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
  z-index: 0;
  animation: fadeIn50 0.2s ease-in-out;
}

.prompt-dialog.hide {
  display: none;
}

/* Modal container */
.dialog-container {
  background: #fff;
  /* White background */
  padding: 2rem 3rem;
  border-radius: 3rem;
  /* Rounded corners */
  border: 0.1rem solid #bbb;
  box-shadow: 0 0.3rem 0 0 #ccc;
  box-sizing: border-box;
  width: 96%;
  text-align: center;
  /* Center the content */
  z-index: 1;
  animation: slideUpDialog 0.15s ease-out;
}

/* Icon */
.icon-container {
  margin: 1rem auto 1.5rem auto;
  height: 5rem;
  width: 5rem;
}

/* Title */
.dialog-title {
  font-size: 1.8rem;
  font-weight: bold;
  color: #333;
  /* Dark gray */
}

/* Description */
.dialog-description {
  margin: 1.5rem 0;
  font-size: 1.4rem;
  color: #555;
  /* Medium gray */
  line-height: 1.5;
}

/* Button container */
.dialog-buttons {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  /* Space between buttons */
  margin-top: 3rem;
}

.dialog-buttons .dialog-confirm {
  color: #fff;
  border: none;
  font-weight: bold;
  background-color: #ff6700;
  box-shadow: 0 0.2rem 0 0 #ccc;
}

.dialog-cancel {
  margin: 0.7rem;
  color: #ff6700;
}

/* Center single button */
.dialog-buttons > *:only-child {
  margin: 0 auto;
}

/* Fade-in animation for the modal */
@keyframes fadeInPrompt {
  from {
    opacity: 0;
    transform: scale(0.95);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.prompt-icon {
  font-size: 4rem;
  color: #555;
}

.description {
  font-size: 1.3rem;
  line-height: 1.7rem;
  margin-bottom: 2.5rem;
}