.nav {
  --initial-width: 4.8rem;
  --expanded-width: 95%;
  --height: 4.8rem;
  --nav-title-height-top-margin: 0.5rem;
  --nav-title-height: calc(4.2rem - var(--nav-title-height-top-margin));


  z-index: 200;
  position: fixed;
  top: 0.6rem;
  left: calc(50% - var(--expanded-width) / 2);
  overflow: hidden;

  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  width: var(--initial-width);
  height: var(--height);
  border-radius: 2.2rem;
  /*calc(var(--height) / 2);*/

  font-size: 1.7rem;
  color: #333;
  background-color: #fff;
  border: 0.1rem solid #aaa;
  box-shadow: 0 0.4rem 0 0 #bbb;
  padding: 0.1rem;


  transform: scale(0);
  opacity: 0;

  animation:
    scaleInBounce 0.5s cubic-bezier(0.42, 0, 0.58, 1) forwards,
    expandToPill 1s cubic-bezier(0.42, 0, 0.58, 1) forwards;
  /* expandToPill used in js to determine when nav is ready */
  animation-delay: 0s, 0.3s;
  transition: height 0.2s ease-in-out;

  user-select: none;
}

@media (display-mode: fullscreen) {
  .nav {
    top: calc(0.6rem + env(safe-area-inset-top));
  }
}

.nav.expand {
  transition: height 0.35s cubic-bezier(0.33, 1, 0.68, 1.1);
  height: 31rem;
}

.nav .top {
  display: flex;
  box-sizing: inherit;
  justify-content: center;
  align-items: center;
  height: var(--nav-title-height);
  margin-top: var(--nav-title-height-top-margin);
  width: 100%;
}

.nav .months-container {
  position: relative;
  margin: 0 auto;
  padding: 0;
  height: var(--nav-title-height);
  width: 60%;
  overflow: hidden;
  opacity: 0;
  animation:
    textAppear 1s cubic-bezier(0.42, 0, 0.58, 1) forwards;
  animation-delay: 0.8s;
  box-sizing: border-box;

  /* Flexbox to center content */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.nav .months-container .month-title {
  width: 96%;
  height: var(--nav-title-height);
  line-height: var(--nav-title-height);
  display: block;
  text-align: center;
  box-sizing: border-box;
  font-weight: bold;
  color: #333;
}

.nav .top .month-title .icon {
  margin-left: 0.4rem;
  transition: transform 0.4s ease-in-out;
}

.nav.expand .top .month-title .icon {
  font-size: 1.3rem;
  margin-left: 0.4rem;
  transform: rotate(-180deg);
}

.nav .divider {
  height: 0.1rem;
  margin: 0.8rem auto 1.5rem auto;
  width: 90%;
  background-color: #eee;
}

.nav .bottom {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.nav .bottom .calendar-container {
  width: 96%;
  overflow: hidden;
}

.nav .bottom .calendar-tray {
  display: flex;
  flex-wrap: nowrap;
}

.nav .bottom .calendar-tray .calendar-details {
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  box-sizing: border-box;
}

.nav .bottom .calendar-tray .calendar-details .calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 0.1rem;
  /* Reduced from 0.2rem */
  padding: 0.2rem;
}

.nav .bottom .calendar-tray .calendar-details .heading {
  display: flex;
  justify-content: space-between;
  gap: 0.2rem;
}

.nav .bottom .calendar-tray .calendar-details .heading .label {
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: bold;
  width: 100%;
}


.nav .bottom .calendar-tray .calendar-details .calendar .day {
  border: none;
  font-size: 1.5rem;
  font-weight: normal;
  border-radius: 0;
  background-color: transparent;
  position: relative;
  overflow: visible;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0;
  /* Add height to control vertical space */
  height: 3.5rem;
  /* This controls the row height */
}

.nav .bottom .day.active::after {
  content: '';
  position: absolute;
  bottom: -0.1rem;
  left: 40%;
  width: 20%;
  height: 0.3rem;
  background-color: #ff6700;
}

.nav .bottom .day.noclick {
  pointer-events: none;
  /* Disable mouse interactions */
  cursor: not-allowed;
}

.nav .bottom .day .date {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width: 2.5rem;
}

.nav .bottom .day.events .date {
  border: 0.2rem solid #dcdcdc;
  border-radius: 50%;
}

.nav .bottom .day.prev-month .date {
  color: #ccc;
}

.nav .bottom .calendar-tray .calendar-details .calendar-events-container {
  margin-top: 0.6rem;
  margin-left: auto;
  margin-right: auto;
  height: 3.2rem;
  width: 95%;
  color: #fff;
  border-radius: 1.2rem;
  font-size: 1.6rem;
  text-align: left;
  line-height: 3rem;
}

.nav .bottom .close-btn {
  display: none;
}

.nav.expand .bottom .close-btn {
  display: inline;
  position: absolute;
  color: #ccc;
  font-size: 1.7rem;
  bottom: 1rem;
  right: 1rem;
  cursor: move;
}

.nav button {
  padding: 0;
  margin: 0;
  width: 3.6rem;
  aspect-ratio: 1 / 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  box-shadow: none;
}

.nav button.profile,
.nav button.latest,
.nav button.today {
  position: absolute;
  top: 0.5rem;
}

.nav button.profile {
  right: 0.5rem;
  font-size: 2.8rem;

  height: auto;
  background-color: #fff;
  border: 0.2rem solid #ff9e3d;
  border-radius: 50%;
}

.nav button.latest {
  left: 0.8rem;
  font-size: 2rem;
  color: #aaa;

  opacity: 0;
  animation:
    textAppear 1s cubic-bezier(0.42, 0, 0.58, 1) forwards;
  animation-delay: 0.6s;
}

.nav button.today {
  right: 0.5rem;
  border: none;
  background-color: transparent;
}

.nav button.today .circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.6rem;
  height: 2.6rem;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  background-color: #ff6700;
  box-sizing: border-box;
}

.nav button.today .D {
  font-size: 1.6rem;
  color: #fff;
}