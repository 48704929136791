.toast-container {
    position: fixed;
    bottom: 1.2rem;
    left: 50%;
    transform: translateX(-50%);
    width: 96%;
    display: flex;
    align-items: center;
    background: #333;
    color: #fff;
    padding: 0.8rem 0.6rem;
    border-radius: 1.5rem;
    border: 0.1rem solid #999;
    box-shadow: 0 0.2rem 0 0 #aaa;
    box-sizing: border-box;

    animation: fadeInSlideUp 0.2s ease-out;
    /* Apply the animation */
    z-index: 10001;
}

@media (display-mode: fullscreen) {
    .toast-container {
        bottom: calc(env(safe-area-inset-top));
    }
}

.toast-container h1,
.toast-container h2,
.toast-container h3 {
    color: #fff;
    text-shadow: none;
}

.toast-container.fade-out {
    animation: fadeOutSlideDown 0.2s ease-out forwards;
}

.toast-icon,
.toast-close {
    margin: 0 0.6rem;
}

.toast-icon {
    width: 2.8rem;
    height: 2.8rem;
    margin-right: 1.4rem;
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    flex-shrink: 0;
    overflow: hidden;
}

.toast-icon img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    display: block;
}

.toast-content {
    flex-grow: 1;
}

.toast-title {
    margin: 0;
    margin-bottom: 0.2rem;
    font-size: 1.5rem;
    font-weight: bold;
}

.toast-description {
    margin: 0;
    font-size: 1.3rem;
    color: #ccc;
}

.toast-close {
    width: 2.8rem;
    font-size: 1.6rem;
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: #fff;
    cursor: pointer;

    display: flex;
    align-items: center;
}