.profile {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    overflow: hidden;
}

.profile .back {
    width: 100%;
    text-align: left;
    padding-top: 2rem;
    padding-left: 4rem;
    margin-bottom: 1rem;
}

.profile .back .icon {
    position: relative;
    top: 0.4rem;
    font-size: 2rem;
    margin-right: 0.5rem;
}

.profile h2 {
    margin-top: 2rem;
}

.profile .benefits {
    padding: 0 4rem;
}

.profile .savings {
    font-size: 1.8rem;
    font-weight: bold;
}

.profile .settings {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    border: 0.1rem solid #555;
    border-radius: 2rem;
    box-shadow: 0 0.2rem 0 0 #ccc;
    overflow: hidden;
    margin: 0 auto;
}

.profile .settings.forward {
    border: none;
    box-shadow: none;
    overflow: visible;
}

.profile .settings.forward .forwarding-address {
    width: 100%;
    margin: 0;
}

.profile .watchlist {
    width: 90%;
    margin-top: -1.6rem;
}

.profile .watchlist .hint {
    background-color: #eaeaea;
}

.profile .title {
    margin-top: 4rem;
    text-transform: uppercase;
    font-size: 1.1rem;
    margin-bottom: 0.3rem;
}

.profile .title.weather img {
    width: 1rem;
    margin-right: 0.4rem;
    vertical-align: -0.1rem;
}

.profile .note {
    margin: 0.6rem auto;
    margin-bottom: 0;
    text-align: justify;
    width: 86%;
}

.profile .note:last-of-type {
    margin-bottom: 2rem;
}

.profile .help {
    font-size: 1.3rem;
    margin-top: 1rem;
}

.profile .help .icon {
    color: #ff6700;
    margin-right: 0.4rem;
}

.profile .help a {
    color:#555;
}

.profile .recommended-addresses .note {
    margin-left: 1rem;
}

.profile .title .icon {
    margin-right: 0.4rem;
}

.profile .settings .title {
    margin-top: 1rem;
}

.profile .settings button:first-child {
    border-top: none;
}

.profile .settings button {
    width: 100%;
    position: relative;
    color: #000;
    background-color: #fff;
    border-width: 0.1rem;
    border-color: #555;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: left;
    padding-left: 4.6rem;
    border-radius: 0;
    border-left: none;
    border-right: none;
    border-bottom: none;
}

.profile .settings button.clear {
    position: absolute;
    background-color: transparent;
    font-size: 1.7rem;
    height: 3.8rem;
    width: 2rem;
    left: calc(60% + 1.4rem);
    color: #aaa;
    box-shadow: none;
}

.profile .settings button .icon {
    position: absolute;
    left: 1.6rem;
    top: 1rem;
    width: 2rem;
}

.profile .settings.sync form {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: -0.1rem;
}

.profile .settings.sync .invitee-input {
    box-sizing: border-box;
    font-size: 1.4rem;
    border: 0.1rem solid #555;
    border-left: none;
    border-bottom: none;
    border-radius: 0;
    padding-left: 1.6rem;
    width: 80%;
    height: 3.4rem;
}

.profile .settings.sync button.right-button {
    box-sizing: border-box;
    font-size: 1.4rem;
    color: #fff;
    border: 0.1rem solid #ff9e3d;
    background-color: #ff6700;
    padding-left: 1rem;
    width: 20%;
    height: 3.4rem;
}

.profile .settings button.gcal {
    background-image: url('../assets/gcallogo.svg');
    background-size: 2rem auto;
    background-position: 1.7rem center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profile .settings button.gcal .status {
    margin-left: 1rem;
    font-weight: normal;
    font-size: 1.2rem;
}

.profile .settings button.gcal .status.connected {
    color: rgb(0, 106, 70);
}

.profile .settings button.notifications .icon.connected {
    color: rgb(0, 106, 70);
}

.profile .settings button.notifications .icon.connect {
    color: #ff6700;
}