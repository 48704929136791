.forwarding-address {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 4rem auto 0.4rem auto;
    height: 3.5rem;
    width: 90%;
}

.settings .forwarding-address button.copy,
.forwarding-address button.copy {
    flex: 0 0 auto;
    padding: 0;
    box-sizing: border-box;
    color: #fff;
    border: 0.1rem solid #ff9e3d;
    border-radius: 0 2rem 2rem 0;
    background-color: #ff6700;
    width: 5rem;
    height: 3.5rem;
}

.settings .forwarding-address button.copy .icon,
.forwarding-address button.copy .icon {
    position: relative;
    top: 0;
    left: -0.2rem;
    width: inherit;
}

.forwarding-address button:active,
.forwarding-address button:focus {
    color: #fff;
    background-color: #ff6700;
}

.forwarding-address .address {
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content: flex-start;
    background-color: #fff;
    border: 0.1rem solid #555;
    border-radius: 2rem 0 0 2rem;
    box-shadow: 0 0.2rem 0 0 #ccc;
    flex: 1;
    min-width: 0;
    margin: 0;
    padding-left: 1rem;
    font-size: 1.5rem;
    height: inherit;
    overflow: hidden;
}

.forwarding-address .address:active,
.forwarding-address .address:hover {
    background-color: rgb(255, 255, 190);
}

.forwarding-address .icon.inbox {
    position: static;
    top: 0;
    width: 5rem;
    margin: 0 0.8rem 0 0.6rem;
}