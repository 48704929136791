.disclosure {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 10;
  }

.disclosure button {
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    box-shadow: none;
}

.disclosure.open .items {
    opacity: 1;
    visibility: visible;
    height: auto;
    border: #aaa solid 0.1rem;
    box-sizing: border-box;
    transition: opacity 0.4s ease, border 0s, visibility 0s, height 0s;
    pointer-events: auto;
}

.disclosure .items {
    box-sizing: border-box;
    border: none;
    border-radius: 0.8rem;
    background-color: #fff;
    padding: 0.2rem;

    opacity: 0;
    visibility: hidden;
    height: 0;
    pointer-events: none;
    transition: opacity 0.4s ease, border 0s, visibility 0s 0.5s, height 0s 0.5s;
}


.disclosure .item {
    margin: 1.1rem 1.6rem 1.1rem 1.1rem;
}

.disclosure .items button {
    display: block;
    width: auto;
    height: auto;
    color: #000;
    background-color: transparent;
    font-size: 1.4rem;
    font-weight: normal;
}

/* When the button is .active, apply the continuous rotation */
.disclosure .item.active .icon {
    animation: rotateSpinner 2s linear infinite;
    /* 2 seconds for one full rotation */
}

.disclosure .item .icon {
    height: 1.3rem;
    width: 1.3rem;
    padding: 0 0.4rem;
    color: #111;
}

.disclosure.open .disclosure-btn {
    color: #000;
}