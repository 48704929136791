.card-modal {
  position: fixed;
  top: 0.8rem;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.card-content {
  --drawer-vOffset: 0.4rem;
  --drawer-height: calc(100% - (var(--drawer-vOffset) *2));

  position: relative;
  left: 50%;
  bottom: calc(-1 * var(--drawer-height));
  transform: translateX(-50%);

  min-width: 360px;
  max-width: 414px;
  width: 100%;
  height: var(--drawer-height);
  
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;

  padding: 0.8rem;
  border-radius: 2.6rem;
  background-color: #fbfbfb;

  transition: bottom 0.3s ease-in-out;
  z-index: 1100;
}

.card-content.loaded {
  bottom: var(--drawer-vOffset);
}

@media (display-mode: fullscreen) {
  .card-modal {
    top: calc(0.8rem + env(safe-area-inset-top));
  }
  .card-modal.demo-mode {
    top: calc(5.7rem + env(safe-area-inset-top));
  }

  .card-content {
    height: calc(var(--drawer-height) - (env(safe-area-inset-top) + env(safe-area-inset-bottom)));
  }
}

.card-modal .shadow {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: #000;
  transition: opacity 0.5s ease-in-out;
  z-index: 1001;
}

.card-modal .shadow.loaded {
  opacity: 1;
}

.card-content button {
  border: none;
  cursor: pointer;
  color: #999;
  padding: 0;
  font-size: 1.6rem;
  width: 2.7rem;
  height: 2.7rem;
}

.card-content .close-handle {
  position: absolute;
  top: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  width: 20%;
  height: 0.4rem;
  background-color: #ccc;
  border: none;
  border-radius: 0.3rem;
  padding: 0;
  margin: 0;
  cursor: move;
}

.card-content .disclosure,
.card-content .close-btn {
  position: absolute;
  top: 0.8rem;
}

.card-content .disclosure {
  right: 4.2rem;
}

.card-content .close-btn {
  right: 1rem;
}

.card-content .icon {
  width: 2.2rem;
  margin-right: 0.2rem;
}

.card-content .v-scroller {
  margin-top: 3.4rem;
  border-radius: 2.6rem;
  overflow: hidden;
  overflow-y: auto;

  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
  touch-action: pan-y;
}

.card-content .h-scroller {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  background-color: #eeeeee;
  border-radius: 1.2rem;

  overscroll-behavior: contain;
  touch-action: pan-x;
  -webkit-overflow-scrolling: touch;
}