.event-drawer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  z-index: 2000;
}

.event-drawer {
  position: fixed;
  min-width: 36rem;
  max-width: 41.4rem;
  width: calc(100% - 0.6rem);
  bottom: -100%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  z-index: 2;
}

.event-drawer-overlay.event-loaded .event-drawer {
  animation: slideUp 0.3s ease-in-out forwards;
}

.event-drawer-overlay .shadow {
  background-color: #000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  z-index: 1;

  touch-action: none;
  -webkit-overflow-scrolling: none;
  overscroll-behavior: contain;
}

.event-drawer-overlay.event-loaded .shadow {
  opacity: 1;
}

.source {
  pointer-events: auto !important;
}

.event-content {
  --maximized-height: 60rem;
  --open-height: 35rem;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  height: var(--open-height);
  background-color: #f9f9f9;
  padding: 0.5rem 3rem 1.2rem;
  border-radius: 3rem 3rem 0 0;
  border: 0.4rem solid #fff;
  border-bottom: none;
  box-shadow: 0 0.1rem 0.1rem #777;
  text-align: left;
  cursor: auto;
}

.event-content .close-handle {
  width: 20%;
  height: 0.8rem;
  background-color: #ccc;
  border-radius: 0.4rem;
  padding: 0;
  margin: 0 auto 1.2rem auto;
  cursor: move;
}

.event-content .close-btn {
  position: absolute;
  color: #ccc;
  font-size: 1.7rem;
  top: 1.6rem;
  right: 1.7rem;
  cursor: move;
}

.event-content .header {
  color: #777;
  font-size: 1.6rem;
  padding-bottom: 1rem;
}

.event-content .header .icon {
  padding-left: 0.4rem;
  padding-right: 0.6rem;
}

.event-content .event {
  -webkit-tap-highlight-color: transparent; /* Disable tap flash */
  user-select: none; /* Disable text selection */
  -webkit-user-select: none; /* For Safari */
  cursor: pointer;

  display: flex;
  flex-direction: column;
  aspect-ratio: unset;
  margin: 0 -1.4rem;
  padding: 1.1rem 1.6rem;
  width: auto;
  height: 100%;
  border-radius: 1rem;
  border: 0.1rem solid #f2f2f2;
  background-color: #fff;
  box-shadow: 0 0.2rem 0 0 #e1e1e1;
  height: 100%;
  min-height: 0; /* Allow container to shrink */
}

.event-content .event:active, 
.event-content .event:focus {  
  transform: none;
  box-shadow: 0 0.2rem 0 0 #e1e1e1;
}

.event-content .event.firstview:active, 
.event-content .event.firstview:focus {  
  transform: none;
  box-shadow: 0 0.2rem 0 0 #e1e1e1;
}

.event-content .event .base-content {
  font-size: 1.4rem;
  box-sizing: border-box;
  overflow: hidden;
  min-height: 14.5rem;
}

.event-content .event .extended-content {
  flex-grow: 1; /* Only takes space if available */
  overflow: hidden;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  font-size: 1.4rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0; /* Allow flex child to shrink */
}

.event-content .event .extended-content .calendar {
  margin-bottom: 1rem;
  box-sizing: border-box;
  padding: 0.1rem;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  flex: 1;
  min-height: 0; /* Allow scrolling to work */
  height: 0; /* Force content to scroll */
  pointer-events: auto;

  touch-action: pan-y;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
}

.event-content .event .action {
  margin-top: auto;
  flex: 0 0 6rem; /* Changed from flex: 1 */
  display: flex;
  justify-content: space-between; /* Space between the first and other children */
  align-items: flex-end;
  margin-top: 1rem;
  padding: 0.8rem 0;
  box-sizing: border-box;
  flex-shrink: 0; /* Prevent shrinking */
  height: 5rem;
  max-height: 5rem;
  min-height: 5rem;
}

/* Hide .extended-content if it gets squeezed out */
.event-content .event:not(:has(.extended-content)) .extended-content {
  display: none;
}

.event-content .event .icon {
  padding-right: 0.8rem;
}

.event-content .disclosure {
  position: absolute;
  top: 1.6rem;
  right: 1rem;
}

.event-content .disclosure .disclosure-btn {
  color:#777;
  padding-right: 0.6rem;
}

.event-content .disclosure.open .disclosure-btn {
  color:#000;
}

.event-content .event .title {
  width: calc(100% - 3rem);
  font-size: 1.8rem;
  line-height: 1.2;
  padding-bottom: 1.35rem;
}

.event-content .event .day, 
.event-content .event .month {
  font-size: 1.4rem;
  font-weight: bold;
  padding-top: 0.4rem;
  padding-bottom: 0.6rem;
}

.event-content .event .start {
  font-weight: bold;
  padding-bottom: 0.6rem;
  display: inline-block;
}

.event-content .event .end {
  font-weight: bold;
  padding-bottom: 0.6rem;
  display: inline;
}

.event-content .event .description {
  box-sizing: border-box;
  padding-top: 1.2rem;
  display: -webkit-box;
  line-height: 1.3;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
  text-overflow: ellipsis;
}

.event-content .event .extended-content .divider {
  height: 0.1rem;
  margin: 2.5rem auto -2rem auto;
  width: 99%;
}

.event-content .event .extended-content .title {
  margin: 2.4rem 0 0.4rem 0;
  padding: 0;
  font-size: 1.4rem;
  font-weight: bold;
  overflow: visible;
}

.event-content .event .current-schedule {
  margin-bottom: 0.6rem;
  margin-left: 0.2rem;
}

.event-content .event .weather .forecast {
  color: #555;
  font-size: 1.3rem;
}

.event-content .event .weather .forecast .icon {
  padding-left: 1rem;
  padding-right: 0.2rem;
}

.event-content .event .weather .forecast .symbol {
  padding-right: 0.1rem;
}

.event-content .event .weather {
  margin: 0.1rem;
  padding: 1rem;
  border-radius: 1.2rem;
  border: 0.1rem solid #eee;
  background-color: #f9f9f9;
}

.event-content .event .extended-content .connect-gcal {
  text-align: center;
}

.event-content .event .extended-content .connect-gcal button {
  box-shadow: none;
  padding: 2rem 0;
}


.event-content .event .extended-content .calendar .ext-event {
  margin-bottom: 0.8rem;
  padding: 0.5rem 1.1rem;
  color: #555;
  background-color: #f9f9f9;
  border-radius: 1.2rem;
  border: 0.1rem solid #eee;
  display: flex;
  justify-content: space-between;  /* Add this */
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  min-width: 0;
}

.event-content .event .extended-content .calendar .ext-event .title {
  display: inline-block;
  margin: 0;
  padding: 0;
  font-size: 1.3rem;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 16rem;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}

.event-content .event .extended-content .calendar .ext-event .time {
  font-size: 1.2rem;
  text-align: right;
  max-width: 11rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.event-content .event .extended-content .calendar .ext-event.saved {
  color: #ff6700;
  background-color: #fff;
}

.event-content .event .extended-content .calendar .ext-event.removed {
  opacity: 1;
  animation: scaleOutBouncePillRemove 0.5s cubic-bezier(0.42, 0, 0.58, 1) forwards;
}

.event-content .event .extended-content .calendar .ext-event.added {
  opacity: 0;
  animation: scaleInBouncePill 0.35s cubic-bezier(0.42, 0, 0.58, 1) forwards;
}

.event-content .event .link {
  padding-top: 1.6rem;
}

.event-content .sources {
  justify-content: flex-end;
  padding-top: 1.2rem;
  width: auto;
}

.event-content .person-component {
  margin-right: -0.4rem;
}

.event-content .sources .source-messages {
  display: flex;
}

.event-content .sources .source .pdf-source {
  position: absolute;
  font-size: 1.2rem;
  bottom: -1rem;
  right: -1.5rem;
}

.event-content .sources .note {
  margin-top: 0.1rem;
  margin-left: 0.5rem;
  overflow: hidden;           
  text-overflow: ellipsis;    
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 30rem;
}

.event-content .action button {
  width: 6rem;
  color: #fff;
  border: none;
  font-weight: bold;
  background-color: #ff6700;
  box-shadow: 0 0.2rem 0 0 #ccc;
}

.event-content .action button.go {
  position: relative;
  display: flex;
  align-items: center;
  width: 8.6rem;
}

.event-content .action button.go .hint {
  position: absolute;
  top: calc(100% + 0.3rem);
  left: 0;
  color: #888;
  width: inherit;
  font-size: 0.9rem;
  font-weight: normal;
  font-variant: lowercase;
}

.event-content .action .right-action {
  margin-left: auto;
  display: flex;
  gap: 0.6rem; 
}

.event-content .action button.go .label,
.event-content .action button.go .icon {
  padding-left: 0.8rem;
}

@keyframes popEffect {
  0% {
    box-shadow: 0 0.2rem 0 0 #ccc;
    transform: translateY(0);
  }
  60% {
    box-shadow: 0 0.3rem 0 0 #ccc;
    transform: translateY(-0.1rem);
  }
  80% {
    box-shadow: 0 0.1rem 0 0 #ccc;
    transform: translateY(0.1rem);
  }
  100% {
    box-shadow: 0 0.2rem 0 0 #ccc;
    transform: translateY(0);
  }
}

@keyframes scaleInBigBounce {
  0% {
    transform: scale(0) rotate(0deg);
    opacity: 0;
  }

  60% {
    transform: scale(3) rotate(15deg);
    opacity: 1;
  }

  80% {
    transform: scale(0.9) rotate(-10deg);
    opacity: 1;
  }

  100% {
    transform: scale(1) rotate(0deg);
    opacity: 1;
  }
}

@keyframes glowEffect {
  0% {
    box-shadow: 0 0 0 #ff6700;
    opacity: 1;
  }
  50% {
    box-shadow: 0 0 2rem #ff6700;
    opacity: 0.8;
  }
  100% {
    box-shadow: 0 0 4rem #ff6700;
    opacity: 0;
  }
}

@keyframes collapse-circle {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    transform: translate(-50%, -50%) scale(0);
  }
}

.event-content .action button.like {
  color: #fff;
  background-color: #ff6700;
  border: 0.2rem solid #ff6700;
  height: 3.6rem;
  position: relative;
  overflow: hidden;
}

.event-content .action button.like.true {
  color: #ff6700;
  border-color: #ff6700;
  background-color: transparent;
  animation: popEffect 0.4s cubic-bezier(0.42, 0, 0.58, 1) forwards;
  animation-delay: 0.55s;
  overflow: visible;
}

/* Ensure .fill wraps the animation and hides overflow */
.event-content .action button.like.true .fill {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  overflow: hidden; /* Hides overflow */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Circle collapse effect inside .fill */
.event-content .action button.like.true .fill::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8.6rem;
  height: 8.6rem;
  background: #ff6700;
  border-radius: 50%;
  transform: scale(1);
  animation: collapse-circle 0.3s cubic-bezier(0.42, 0, 0.58, 1) forwards;
}

.event-content .action button.like .icon {
  animation: none;
  padding: 0;
  opacity: 1;
}

.event-content .action button.like.true .icon {
  opacity: 0;
  animation: scaleInBigBounce 0.4s cubic-bezier(0.42, 0, 0.58, 1) forwards;
  animation-delay: 0.4s;
}

/* Glowing effect on pseudo-element 
.event-content .action button.like.true::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  box-shadow: 0 0 0px #ff6700;
  opacity: 1;
  animation: glowEffect 1.4s ease-out 0.4s forwards; 
}
  */

.event-content .action button.like.false,
.event-content .action button.dislike.false {
  color: #fff;
}

.event-content .coachmark {
  font-size: 1.4rem;
  position: absolute;
  color: #00BB00;
}

.event-content .coachmark .instruction {
  display: inline;
}

.event-content .sources .coachmark {
  bottom: 3rem;
  left: 7rem;
  width: 200%;
  
  opacity: 0; /* Start invisible */
  animation: 
    fadeIn 0.3s ease-in forwards 1.1s, /* Fade-in animation with delay */
    fadeStrobe 2.2s ease-in-out infinite 1.4s; /* Strobe animation starts after fadeIn (*/
}

.event-content .sources .coachmark .instruction {
  vertical-align: 0.3rem;
  margin-left: 0.2rem;
 }

.event-content .bolt.coachmark {
  bottom: 1.6rem;
  right: 8.8rem;
  opacity: 0; /* Start invisible */
  animation: 
    fadeIn 0.3s ease-in forwards 0.7s; /* Fade-in animation with delay */
}

.event-content .bolt.coachmark .instruction {
  vertical-align: 0.2rem;
  margin-left: 0.2rem;
 }

 .event-content .bolt.coachmark .icon {
  position: absolute;
  top: -0.1rem;
  left: -1.8rem;
 }

.event-content .coachmark.hide {
  opacity: 1;
  animation: fadeOut 0.2s ease-in forwards; /* Fade-out animation */
  animation-delay: 0s;
}

.event-content .coachmark .icon {
 display: inline;
 position: unset;
 color: #00D100;
 padding: 0.2rem;
}

@media (display-mode: fullscreen) {
  .event-content {
    padding-bottom: calc(env(safe-area-inset-bottom) + 0.2rem);
  }

  .event-content .sources .coachmark {
    bottom: calc(2.4rem + env(safe-area-inset-bottom));
  }
}