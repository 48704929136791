html {
  font-size: 10px;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100dvh;
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-size: 1.4rem;
  background-color: #eaeaea;
}

@media (display-mode: fullscreen) {
  :root {
    --fs-top-offset: env(safe-area-inset-top); /* Read this from js */
  }

  body {
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
  }
}

/* Check for font variation support */
@supports (font-variation-settings: normal) {
  body {
    font-family: 'Inter var', sans-serif;
  }
}

#root {
  min-width: 360px;
  /* horizontal scrolling for devices with smaller widths */
  max-width: 600px;
  /* extra wide screens, or landscape will limit width of root div */
  width: 100%;
}

h1 {
  font-size: 3.2rem;
  line-height: 1.1;
  color: #222;
  text-shadow: 0.1rem 0.1rem 0 white; /* Offset x, y, blur radius, color */
}

h2 {
  font-size: 2.2rem;
  line-height: 1.2;
  color: #222;
  text-shadow: 0.1rem 0.1rem 0 white; /* Offset x, y, blur radius, color */
}

h3 {
  font-size: 1.6rem;
  line-height: 1.2;
  color: #222;
  text-shadow: 0.1rem 0.1rem 0 white; /* Offset x, y, blur radius, color */
}

ol {
  padding-inline-start: 0;
  text-align: left;
}

li {
  margin-bottom: 1rem;
}

.home .join {
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home .join button {
  margin-top: 2.4rem;
  background-color: #ff6700;
  color: #fff;
}

.home .icon-container .icon {
  font-size: 4rem;
}

.home h3 {
  margin: 1rem 0;
}

.beta {
  position: relative;
  display: inline-block;
  color:#bbb;
  font-size: 1.2rem;
  padding: 0.2rem 0.7rem;
  border-radius: 2rem;
  border:#bbb solid 0.1rem;
  margin-top: 5rem;
}

.faqs {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 90%;
  background-color: #fff;
  border: 0.1rem solid #555;
  border-radius: 2rem;
  box-shadow: 0 0.2rem 0 0 #ccc;
  overflow: hidden;
  margin: 0 auto 2rem auto;
  padding: 0 0.8rem 2rem 0.8rem;
}

.faqs .faq {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  width: 100%;
}

.faqs .faq .question {
  font-weight: bold;
  font-size: 1.4rem;
  margin: 2rem 0 0.4rem 0;
}

.faqs .faq .question .icon {
  width: 2rem;
  margin: 0 0.8rem;
  color: #ff6700;
}

.faqs .faq .answer {
  font-size: 1.3rem;
  margin: 0 1rem 0 3.8rem;
}

.faqs .faq .answer a {
  color: #000;
}

.onboarding {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100dvh;
  box-sizing: border-box;
}

.onboarding-title {
  width: 100%;
  margin-top: 4rem;
}

.onboarding-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  text-align: center;
  width: 92%;
}

.onboarding-main.forward.inbox .forwarding-address {
  width: 100%;
}

.onboarding-main.forward.inbox .forwarding-address button.copy {
  width: 4rem;
}

.onboarding-continue {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  text-align: center;
  margin-bottom: 4rem;
}

.onboarding-continue button.continue {
  position: relative;
}

.onboarding-continue button.continue .icon.spinner {
  position: absolute;
  font-size: 1.4rem;
  top: 1.2rem;
  right: 9rem;
  animation: rotateSpinner 2s linear infinite;
}

.join-calendar a.back {
  display: block;
}

.note, .onboarding .note.legal {
  font-size: 1rem;
  color: #555;
}

.onboarding .note {
  font-size: 1.3rem;
  height: 1.4rem;
}

button, a {
  /* disable browser focus */
  outline: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-focus-ring-color: transparent;
}

button {
  padding: 0.8rem 1.6rem;
  border: none;
  border-radius: 2rem;
  color: #ff6700;
  background-color: #fff;
  font-size: 1.4rem;
  font-weight: bold;
  cursor: pointer;
  transition: box-shadow 0.3s;
  box-shadow: 0 0.2rem 0 0 #ccc;
}

button:active, 
button:focus {  
  outline: none;
  transform: translateY(0.2rem);
  box-shadow: none;
  color: #FF8C33;
  background-color: #ddd;
}

button:disabled {
  opacity: 0.5;
  cursor: auto;
}

button.continue {
  font-size: 1.8rem;
  width: 80%;
  margin: 1rem 0;
}

button.clear {
  position: absolute;
  border: none;
  background-color: transparent;
  font-size: 1.7rem;
  height: 3.8rem;
  left: calc(60% + 2rem);
  color: #aaa;
}

button.cancel, button.alternate {
  font-size: 1.6rem;
  border: none;
  box-shadow: none;
  background-color: transparent;
}

.google-button,
.google-button:hover {
  background-image: url('./assets/glogo.svg');
  background-color: #fff;
  background-size: 2.6rem auto;
  background-position: 0.8rem center;
  background-repeat: no-repeat;
}

.gmail-button,
.gmail-button:hover {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAACsElEQVR4Ae2WA6xcQRiFp7ZtBzX23rqdt65tO6xtM65t27Zt2+3dZ3v636R/sHi5mXUyX3LWON/uSTLEowgEAoHgVuXyo25WrvAY8vlmlQq3b1SpOOAyJVmJj2GXSc7U81m6pFzIejzlYtbXcL0/9UKWHsQVakkovR3C7HOjcoXzd6qWKUt8RNLZ7DWh8GMIsw/ILCLOuFmpwjgs7CK2WxUrWoiXgV95ABSNwcLOknwh2xDH6VSqcDkjAcxfg24Jo9Tjk1Jojby2dvX2qAU15DqxB8qlaBFQDBJEvh5GG3psUn/MupqKXnqufrZGgQhnAkyjAMb21yi7PSnFIA+AxODnahRg7gtg9NIinkmpk4H3b8bP8ZcA16QUWr8qvO8xhHlM4ETtxgq/gPZJwet64GR4Bd6cKfSV2GOcHxW+uUWfMA4BTZP6TGlOm1G3Gl/HK7D3ZLVf0p4uscQe/YJYZlgQkzq914rY61WqcAlgbAbpvEKlks4nwycQdT4nm3S4UaRuTxemxqkApv/o20mnajXkFcD8/mOSDA6T4RB4e7YQ67TfmqgW1ySgpu2M7ynbmvXgF+CKo8CRk5VZsz0d07C4JgGMcX502ryuS9NwUr4SwMnMPCIzLMwlgOk79t7fMzUb+EoAJ5OAZd0SwJjnRoRtoAM/eFtg94lqXxvt6YzlPSeAUfTyJPiiFM+Xl2MgQ7Cg1wQIoBgbNIMv/e6x8nCQUw90BPCJgMov2qAofPlpDwhsVs9FBPCpAIKT4pzMAIL4S4BrUkb5vWKS6hLEnwIck9qNkwkoAQ2TSrAZdCMIEogCyP8z0G/nkwlwAUQ9jaqnUueTCXwBboSAEOBGCMTFBI3A7s7Xnf0Dm4NFQNrduQexh85W8kLBSZDPASwQ0WBvl2X0Ms1KAIFAIBAI/gE4ZSpQR8vzCwAAAABJRU5ErkJggg==');
  background-size: 2.4rem auto;
  background-position: 1.5rem center;
  background-repeat: no-repeat;
}

.gmail-button .secure {
  font-size: 1.1rem;
  padding-bottom: 0.1rem;
}

.gcal-button,
.gcal-button:hover {
  background-image: url('./assets/gcallogo.svg');
  background-size: 2rem auto;
  background-position: 1.8rem center;
  background-repeat: no-repeat;
}

.email-forwarding-button {
  position: relative;
}

.email-forwarding-button .icon {
  position: absolute;
  left: 1.6rem;
  color: #026d02;
}

.success button {
  color: #ff6700;
}

.close-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #777;
  box-shadow: none;
}

.close-btn:hover {
  color: #ff6700;
}

.attention-marker {
  z-index: 201;
  position: fixed;
  top: 6rem;
  width: 0.4rem;
  left: -1rem;
  height: 5rem;
  background-color: #ff6700;
}

.attention-marker.show {
  z-index: 201;
  position: fixed;
  top: 6rem;
  left: 0;
  width: 0.4rem;
  height: 5rem;
  transition: left 0.4s ease-in-out;
  background-color: #ff6700;
}

.today-pointer {
  z-index: 203;
  display: flex;
  
  color:#999;
  position: fixed;
  font-size: 3.6rem;
  bottom: 1.8rem;
  left: 2.2rem;
  width: 4.6rem;
  height: 4.6rem;
  box-shadow: 0 0.2rem 0 0 #ccc;
  border-radius: 1rem;
  background-color: #f9f9f9;
  border: 0.1rem solid #ccc;
}

.today-pointer .label {
  position: absolute;
  font-weight: bold;
  left: 50%;
  transform: translateX(-50%);
  color:#ff6700;
  font-size: 0.9rem;
  text-transform: uppercase;
}

.today-pointer .icon {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 2.2rem;
  height: 2.2rem;
}

.today-pointer.up .icon {
  top: 0.6rem;
  bottom: auto;
}

.today-pointer.up .label {
  top: auto;
  bottom: 0.6rem;
}

.today-pointer.down .icon {
  top: auto;
  bottom: 0.6rem;
}

.today-pointer.down .label {
  top: 0.6rem;
  bottom: auto;
}

.notice {
  z-index: 202;
  color:#aaa;
  position: fixed;
  font-size: 1rem;
  text-shadow: 0.1rem 0.1rem 0 white; /* Offset x, y, blur radius, color */
  bottom: 0.5rem;
  width: 100%;
  text-align: center;
}

.demo-mode .demo-skip {
  z-index: 9999;
}

.demo-mode .demo-complete {
  position: absolute;
  bottom: 4rem;
  display: none;
  left: 50%;
  transform: translateX(-50%);
}

.demo-mode .demo-complete.show {
  width: 100%;
  display: block;
  opacity: 0;
  animation: fadeIn 0.2s ease-in forwards; /* Fade-in animation */
  animation-delay: 5s;
}

.demo-mode .demo-complete .smile {
  font-size: 10rem;
  color: #cecece;
  
}

.demo-mode .demo-complete h2 {
  font-size: 2.4rem;
  color: #444;
  margin-bottom: 0.4rem;
}

.demo-mode .demo-complete .description {
  color: #444;
  margin-bottom: 2rem;
}

.demo-mode .demo-complete button {
  position: relative;
}

.demo-mode .demo-complete button .icon {
  position: absolute;
  color:#444;
  width: 2.6rem;
  left: 1rem;
}

.demo-mode .demo-skip {
  position: fixed;
  top: 0rem;
  right: 1rem;
  padding: 2rem;
  height: 2.2rem;
  display: flex;
  align-items: center;
  color: #808080;
}

.demo-mode .demo-title {
  position: fixed;
  top: 0.2rem;
  left: 50%;
  transform: translateX(-50%);
  padding: 2rem;
  height: 2.2rem;
  font-weight: bold;
  color: #222;
}

.demo-mode .demo-skip .icon {
  height: 1.6rem;
  margin-left: 0.9rem;
}

.feed-container {
  position: relative; /* reset after demo */
  top: auto;
  padding-bottom: 1rem;
}

.feed-container.demo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.feed-container.demo .month-container {
  border: none;
}

.feed-container.demo .month-title {
  color: transparent;
}

.feed-container.demo .event {
  position: relative;
  overflow: visible;
}

.feed-container.demo .event .coachmark {
  position: absolute;
  top: 3.8rem;
  left: calc(100% + 0.6rem);
  width: 200%;
  color: #ff6700;
  opacity: 0; /* Start invisible */
  animation: 
    fadeIn 0.3s ease-in forwards 1.1s, /* Fade-in animation with delay */
    fadeStrobe 2.2s ease-in-out infinite 1.4s; /* Strobe animation starts after fadeIn (*/
}

.feed-container.demo .event .coachmark.hide {
  opacity: 1;
  animation: fadeOut 0.2s ease-in forwards; /* Fade-out animation */
  animation-delay: 0s;
}

.feed-container.demo .event.upcoming .coachmark .icon,
.feed-container.demo .event.firstview .coachmark .icon,
.feed-container.demo .event .coachmark .icon {
 display: inline;
 position: unset;
 color: #00D100;
 padding: 0.2rem;
}

.feed-container.demo .event .coachmark .instruction {
  font-size: 1.4rem;
  display: inline;
  vertical-align: 0.4rem;
  margin-left: 0.2rem;
  color: #00BB00;
 }

 .feed-container .month-container {
  border-top: 0.1rem solid #dcdcdc;
  margin-top: 1.2rem;
  margin-left: 2%;
  margin-right: 2%;
  padding-top: 1rem;
  box-sizing: border-box;
}

.feed-container .month-container .month-title {
  margin-top: 1rem;
  font-size: 1.7rem;
}

.today, .nav .months-container .month-title.today {
  color: #ff6700;
  font-weight: bold;
}

.focus {
  max-height: 100dvh;
  width: 100%;
}

.loading-details {
  font-size: 1rem;
}

.month-events-container:first-child, .day-container:first-child {
  margin-top: 5.6rem;
}

.month-events-container:last-child, .day-container:last-child {
  margin-bottom: 1rem;
}

.month-events-container, .day-container {
  margin-top: 1.2rem;
  display: flex;
  align-items: flex-start;
  opacity: 0;
  animation: fadeIn 0.2s ease-in-out forwards;
}

.day-container {
  margin-top: 2rem;
}

.month-events-container .month, .day-container .day {
  width: 10%;
  margin-top: 1.2rem;
  display: grid;
  place-items: center;
}

.month-events-container .month {
  color: #777;
}

.day-container .day .ddd {
  font-size: 1.1rem;
  text-transform: uppercase;
  width: 2.5rem;
  display: block;
}

.day-container .day .MMDD {
  margin-top: -0.3rem;
  font-size: 1.6rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
}

.day.today .MMDD {
  margin-top: -0.2rem;
  color: #fff;
  background-color: #ff6700;
}

.day-container .day .MM,
.day-container .day .DD {
  display: block;
}

.day-container .day .MM {
  line-height: 1.4;
}

.day-container .day .DD {
  margin-top: -0.1rem;
}

.day-container .weather {
  margin-top: 1.2rem;
  font-size: 1.3rem;
  font-weight: normal;
  color:#888;
  display: flex;
  flex-direction: column;
}

.day-container .weather .symbol {
  color: #bbb;
  font-weight: bold;
}

.day-container .weather .temp {
  margin-left: 0.3rem;
}

.event-container {
  width: 90%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
}

.event-container .event {
  -webkit-tap-highlight-color: transparent; /* Disable tap flash */
  user-select: none; /* Disable text selection */
  -webkit-user-select: none; /* For Safari */
  cursor: pointer;
  will-change: transform, opacity;

  position: relative; /* Ensures the pseudo-element is positioned relative to this container */

  box-shadow: 0 0.2rem 0 0 #ccc;
  background-color: #f9f9f9;
  color: #999;
  border: 0.1rem solid #f9f9f9;
  transition: border 0.2s ease-in-out;  /*Adjusted duration and easing */
}

.event-container .event:before {
  content: '';
  position: absolute;
  top: 1.2rem; /* Adjust as needed to position the circle exactly */
  left: 0.9rem; /* Adjust as needed */
  width: 0.5rem; /* Circle diameter */
  height: 0.5rem; /* Circle diameter */
  background-color: #bbb; /* Circle color */
  border-radius: 50%; /* Makes it a circle */
}

.month-events-container .event-container .event:before {
  top: 0.6rem;
}

.event-container .event.upcoming:before {
  background-color: #ff6700; /* Circle color */
}

.event-container .event.firstview::before, .event-container .event.none::before {
  display: none;
}

.event-container .event.upcoming, .event-container .event.none {
  background-color: #fff;
  color: #000;
  border: 0.1rem solid #ccc;
}

.event:active, 
.event:focus {  
  transform: translateY(0.2rem);
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.2); /* Reduced shadow for pressed effect */
}

.event.firstview:active, 
.event.firstview:focus {  
  transform: translateY(0.2rem);
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.2); /* Reduced shadow for pressed effect */
}

.event {
  position: relative;
  font-size: 1.25rem;
  border: 0.1rem solid #808080;
  border-radius: 1.2rem;
  margin: 2% 2% 3% 0;
  padding: 7%;
  width: 94%;
  aspect-ratio: 1 / 1;
  box-sizing: border-box;
  overflow: hidden;
}

.event.tentative {
  background: repeating-linear-gradient(
    -45deg,              /* Angle of the stripes */
    #f2f2f2,            /* First color (e.g., white) */
    #f2f2f2 15px,       /* Extend white for 10px */
    #f9f9f9 15px,       /* Start the second color (e.g., blue) */
    #f9f9f9 30px        /* Extend blue for another 10px */
  );
}

.event.upcoming.tentative.firstview {
  background: repeating-linear-gradient(
    -45deg,              /* Angle of the stripes */
    #fff,            /* First color (e.g., white) */
    #fff 15px,       /* Extend white for 10px */
    #f6f6f6 15px,       /* Start the second color (e.g., blue) */
    #f6f6f6 30px        /* Extend blue for another 10px */
  );
}

.month-events-container .event {
  aspect-ratio: none;
  height: 5.6rem;
}

.event.none {
  cursor: auto;
}

.event.duplicate {
  display: none;
}

.event .timing {
  font-weight: bold;
  font-size: 0.95rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.event-container .event .timing {
  padding-left: 1rem;
  transition: padding 0.2s ease-in-out,
}

.event.firstview .timing {
  padding-left: 0;
}

.event .title {
  margin-top: 0.4rem;
  font-size: 1.3rem;
  line-height: 1.1;
  hyphens: auto;             /* Enables hyphenation */
  word-break: break-word;    /* Breaks words at the boundary */
  overflow-wrap: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;
}

.feed-container .event {
  overflow: visible;
}

.event .jr-profile {
  position: absolute;
  text-align: center;
  top: -1rem;
  right: 0.3rem;
  border-radius: 1rem;
  font-size: 1rem;
  background: #eaeaea;
  border: #fff solid 0.1rem;
  color: #a7a7a7;
  padding: 0.2rem 0.4rem;
}
.event.upcoming .jr-profile {
  background: linear-gradient(135deg, #fff, #ffdfd0);
  border: #d4d4d4 solid 0.1rem;
  color: #656565;
}

.month-events-container .event .title {
  width: 78%;
  line-height: 1.5rem;
  max-height: 2.8rem;
  text-overflow: ellipsis;
  line-clamp: 2;
  -webkit-line-clamp: 2;
}

.event-container .event .message,
.event-container .event .icon {
  position: absolute;
  bottom: 0;
}

.event-container .event .icon {
  height: 1.6rem;
  color: #bbb;
  right: 1.1rem;
  bottom: 1rem;
}

.event-container .event.upcoming.firstview .icon {
  height: 1.6rem;
  color: #aaa;
  right: 1.1rem;
  bottom: 1rem;
}

.event-container .event.dislike {
  display: none;
}

.event-container .event .icon.upcoming.important, .event-container .event.firstview .icon.upcoming.important {
  height: 1.4rem;
  width: 1.4rem;
  padding: 0.4rem;
  color: #fff;
  background-color: #ff6700;
  border-radius: 50%;
  right: 0.8rem;
  bottom: 0.6rem;
}

.event-container .event.upcoming .icon.like {
  color: #ff6700;
  animation: scaleInBounce 0.5s cubic-bezier(0.42, 0, 0.58, 1) forwards;
}

.event-container .event .message {
  left: 0.9rem;
  bottom: 0.9rem;
}

.event-container .event .message .count {
  font-size: 1.1rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #aaa;
  padding: 0 0.5rem;
  height: 1.6rem;
  border: 0.1rem solid #ddd;
  border-radius: 1.6rem;
}

.event-container .event.firstview .message .count {
  color: #888
}

.event .info {
  right: 0.9rem;
  bottom: 1rem;
}

.success h1 {
  opacity: 0;
  animation: slideIn 0.5s ease-out forwards;
}

/* CSS for animating the button with a delay */
.success button {
  opacity: 0;
  animation: slideIn 0.5s ease-out forwards;
  animation-delay: 1s;
  /* Adds delay to the button animation */
  box-shadow: 0 0.2rem 0.3rem rgba(0, 0, 0, 0.1);
}

.success .checkmark {
  stroke-dasharray: 14;
  /* Total length of the path */
  stroke-dashoffset: 14;
  animation: draw 0.5s forwards ease-out;
  animation-delay: 0.2s;
  /* Adds delay to the button animation */
}

@media (display-mode: fullscreen) {
  .onboarding {
    padding-bottom: calc( 2rem + env(safe-area-inset-bottom));
  }

  .feed-container.demo {
    top: calc(env(safe-area-inset-top));
  }

  .demo-mode .demo-skip {
    top: calc(env(safe-area-inset-top));
  }
  
  .demo-mode .demo-title {
    top: calc(0.2rem + env(safe-area-inset-top));
  }

  .attention-marker,
  .attention-marker.show {
    top: calc(6rem + env(safe-area-inset-top));
  }

  .today-pointer {
    bottom: calc(2.2rem + env(safe-area-inset-bottom));
  }

  .notice {
    bottom: calc(env(safe-area-inset-bottom));
  }
}